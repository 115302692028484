import { getServerUrl, isDevServer } from 'Helper/envHelpers';
import * as headlines from 'ViatoUi/_exports/headlines.module.scss';
import * as iconSizes from 'ViatoUi/_exports/iconSizes.module.scss';
import * as paragraphs from 'ViatoUi/_exports/paragraphs.module.scss';
import * as roundings from 'ViatoUi/_exports/roundings.module.scss';
import * as shadows from 'ViatoUi/_exports/shadows.module.scss';
import * as sizes from 'ViatoUi/_exports/sizes.module.scss';
import * as spaceSizes from 'ViatoUi/_exports/spaceSizes.module.scss';
import * as types from 'ViatoUi/_exports/types.module.scss';
import * as typesDark from 'ViatoUi/_exports/typesDark.module.scss';
import * as typesLight from 'ViatoUi/_exports/typesLight.module.scss';
import * as typesNormal from 'ViatoUi/_exports/typesNormal.module.scss';
import * as typesShim from 'ViatoUi/_exports/typesShim.module.scss';

export default class Constants {
	/**
	 * Mime-types
	 * All known mime-types: https://www.digipres.org/formats/mime-types/
	 * Internet Assigned Numbers Authority: https://www.iana.org/assignments/media-types/media-types.xhtml
	 */

	static MIME_TYPES = {
		PDF: 'application/pdf', // .pdf
		JPEG: 'image/jpeg', // .jpg, .jpeg, .jfif, .pjpeg, .pjp
		PNG: 'image/png', // .png
		GIF: 'image/gif',
		SVG: 'image/svg+xml',
		AVI: 'video/avi', // .avi
		MP4: 'video/mp4' // .mp4
	};

	static WIDGET_TYPES = {
		DOCUMENT: 'document',
		LOGO: 'logo',
		IMAGE: 'image',
		VIDEO: 'video'
	};

	static TYPES = Object.keys(types);

	static SIZES = Object.keys(sizes);

	static SPACE_SIZES = spaceSizes;

	static ICON_SIZES = iconSizes;

	static HEADLINES = Object.keys(headlines);

	static PARAGRAPH_SIZES = paragraphs;

	static PARAGRAPHS = Object.keys(paragraphs);

	static SHADOWS = Object.keys(shadows);

	static ROUNDINGS = Object.keys(roundings);

	static GET_TYPES_VALUES = () => {
		const typesToReturn = {};
		Object.keys(types).forEach(type => {
			typesToReturn[type] = {};
			typesToReturn[type].dark = typesDark[type];
			typesToReturn[type].normal = typesNormal[type];
			typesToReturn[type].light = typesLight[type];
			typesToReturn[type].shim = typesShim[type];
		});

		return typesToReturn;
	};

	static TYPES_VALUES = this.GET_TYPES_VALUES();

	static BUTTON_TYPES = {
		GHOST: 'ghost',
		PRIMARY: 'primary',
		SECONDARY: 'secondary',
		STRIPE: 'stripe',
		STRIPE_SECONDARY: 'stripe-secondary',
		TEXT: 'text',
		ERROR: 'error',
		SECONDARY_LIGHT: 'secondary-light',
		DARK: 'dark'
	};

	static ALL_BUTTON_TYPES = [
		Constants.BUTTON_TYPES.GHOST,
		Constants.BUTTON_TYPES.PRIMARY,
		Constants.BUTTON_TYPES.SECONDARY,
		Constants.BUTTON_TYPES.STRIPE,
		Constants.BUTTON_TYPES.STRIPE_SECONDARY,
		Constants.BUTTON_TYPES.TEXT,
		Constants.BUTTON_TYPES.ERROR,
		Constants.BUTTON_TYPES.SECONDARY_LIGHT,
		Constants.BUTTON_TYPES.DARK
	];

	static MEDIA_EXPLORER_SIZES = {
		THUMBNAIL_MAX_WIDTH: 250,
		THUMBNAIL_MAX_HEIGHT: 250,
		IMAGE_MAX_SIZE: 1200
	};

	static VIDE_LINK_SERVICE = {
		YOUTUBE: 'youtube',
		VIMEO: 'vimeo'
	};

	static VIDE_LINK_URL = {
		YOUTUBE: 'youtube.com/watch?v=',
		VIMEO: 'vimeo.com/'
	};

	static API_KEYS = {
		YOUTUBE: 'AIzaSyA4IBfno-SnZw-6w0JlUtysB1D2k6NX5_4',
		VIMEO: '8f969d53b863d2ffdaeee54abf0b66e2'
	};

	static FILE_READER_METHODS = {
		READ_AS_ARRAY_BUFFER: 'readAsArrayBuffer',
		READ_AS_BINARY_STRING: 'readAsBinaryString',
		READ_AS_DATA_URL: 'readAsDataURL',
		READ_AS_TEXT: 'readAsText'
	};

	static DIRECTIONS = {
		RIGHT: 'right',
		LEFT: 'left'
	};

	static COMPONENTS_CONFIG = {
		MEDIA_EXPLORER_WIDGET: {
			NUMBER_OF_FILES_ON_PAGE: 10,
			DEFAULT_NUMBER_OF_PAGES: 1
		}
	};

	static DEFAULTS = {
		RICH_TEXT: '<p><br></p>'
	};

	static RATE_SELECT_OPTIONS = {
		SHOW_RATES: 'rate',
		SHOW_PACKAGES: 'package',
		SHOW_RATES_AND_PACKAGES: 'rateAndPackage'
	};

	static COLOR_TYPES = {
		HEX: 'hex',
		RGB: 'rgb'
	};

	static CHANNELS = {
		BOOKING: 2,
		EXPEDIA: 5,
		SECRA: 18,
		BOOKING_ENGINE_V1: 37,
		AIRBNB: 82,
		MY_HOTEL_SHOP: 133,
		GOOGLE: 145,
		BOOKING_ENGINE_V2: 149,
		WIHP: 142
	};

	static PROMOTION_CHANNELS = [Constants.CHANNELS.BOOKING, Constants.CHANNELS.EXPEDIA, Constants.CHANNELS.AIRBNB];

	static VIATO_CHANNELS = [Constants.CHANNELS.BOOKING_ENGINE_V1, Constants.CHANNELS.BOOKING_ENGINE_V2];

	static CHANNELS_DEPENDING_ON_VIATO_CHANNELS = [Constants.CHANNELS.MY_HOTEL_SHOP, Constants.CHANNELS.GOOGLE, Constants.CHANNELS.WIHP];

	static REVIEW_MAX_SCORES = {
		[Constants.CHANNELS.BOOKING]: 10,
		[Constants.CHANNELS.EXPEDIA]: 10,
		[Constants.CHANNELS.AIRBNB]: 5
	};

	static PMS = {
		APALEO: 459,
		MEWS: 460,
		IBELSA: 418,
		SIHOT: 436
	};

	static MODULES = {
		SUITE_PRO: 'suitePro',
		CHANNELMANAGER: 'channelmanager',
		BOOKING_ENGINE_V1: 'crs',
		TASKMANAGER: 'taskmanager',
		WEBSITE_BUILDER: 'websiteBuilder',
		BOOKING_ENGINE_V2: 'bookingEngine'
	};

	static VIATO_CHANNEL_MODULES = [Constants.MODULES.BOOKING_ENGINE_V1, Constants.MODULES.BOOKING_ENGINE_V1];

	static OAUTH_CONFIG = {
		APALEO: {
			URL_PARAMS: ['connect', 'code', 'scope', 'iss'],
			CONNECTION_URL: 'https://identity.apaleo.com/connect/authorize?response_type=code',
			CLIENT_ID: isDevServer() ? 'FQVC-AC-VIATOTEST' : 'FQVC-AC-VIATOPROD',
			REDIRECT_URL: `${getServerUrl()}core?connect=apaleo`,
			SCOPES: [
				'offline_access',
				'distribution:reservations.manage',
				'distribution:subscriptions.manage',
				'rateplans.read-corporate',
				'rateplans.read-negotiated',
				'reservations.read',
				'setup.read'
			]
		}
	};

	static OAUTH_CONNECTION = {
		APALEO: {
			URL: `${Constants.OAUTH_CONFIG.APALEO.CONNECTION_URL}&scope=${Constants.OAUTH_CONFIG.APALEO.SCOPES.join('%20')}&client_id=${
				Constants.OAUTH_CONFIG.APALEO.CLIENT_ID
			}&redirect_uri=${Constants.OAUTH_CONFIG.APALEO.REDIRECT_URL}`
		}
	};
}
