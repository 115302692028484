import './ButtonLogin.scss';

import PropTypes from 'prop-types';
import React from 'react';

const ButtonLogin = ({ children, onClick, type, width }) => {
	return (
		<button className={`react__login-btn react__login-btn-${type}`} style={{ width }} type='submit' onClick={() => onClick()}>
			<div className='label'>{children}</div>
			<i className='far fa-long-arrow-right' />
		</button>
	);
};

ButtonLogin.propTypes = {
	type: PropTypes.oneOf(['primary', 'secondary']),
	width: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node.isRequired
};

ButtonLogin.defaultProps = {
	type: 'primary',
	width: '180px',
	onClick: () => {}
};

export default ButtonLogin;
