import './Headline.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Constants from 'Shared/constants';

const Headline = props => {
	const { children, className, style, type } = props;
	return (
		<div className={`react__headline ${className}`} data-type={type} style={style}>
			{children}
		</div>
	);
};

Headline.propTypes = {
	type: PropTypes.oneOf(Constants.HEADLINES),
	className: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Headline.defaultProps = {
	type: 'h1',
	className: '',
	style: {},
	children: null
};

export default Headline;
