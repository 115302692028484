const test = () => {};

const removeUnwantedWhitespaces = (string, removeBothSides = true) => {
	if (typeof string !== 'string') {
		return string;
	}

	if (removeBothSides) {
		return string.trim().replace(/\s\s+/g, ' ');
	}

	return string.trimStart().replace(/\s\s+/g, ' ');
};

const removeHtmlFromString = string => {
	const tmp = document.createElement('DIV');
	tmp.innerHTML = string;
	return tmp.textContent || tmp.innerText || '';
};

const convertSnakeToCamelCase = string => {
	return string
		.toLocaleLowerCase()
		.split('_')
		.map((part, index) => (index > 0 ? part.charAt(0).toUpperCase() + part.slice(1) : part))
		.join('');
};

const convertCamelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter}`).toUpperCase();

export { test, removeUnwantedWhitespaces, removeHtmlFromString, convertSnakeToCamelCase, convertCamelToSnakeCase };
