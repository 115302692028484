import isArray from 'lodash-es/isArray';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import useDeepCompareEffect from 'ViatoUi/Utils/useDeepCompareEffect';

import * as styles from './Tooltip.module.scss';

const Tooltip = ({ followCursor, place, target, transparent, value }) => {
	const active = useRef(false);
	const tooltipDirection = useRef(place);
	const cursor = useRef({ x: 0, y: 0 });
	const intersectionCheck = useRef(false);
	const ref = useRef(null);
	const [direction, setDirection] = useState(place);
	const tooltipContainer = document.getElementById('react__tooltip-container');

	useDeepCompareEffect(() => {
		const observer = new IntersectionObserver(observerCallback, {
			root: null,
			rootMargin: '0px',
			threshold: [0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
		});

		if (target && target.current && ref.current) {
			observer.observe(ref.current);

			target.current.addEventListener('mouseover', () => {
				handleMouseOver();
			});

			target.current.addEventListener('mousemove', e => {
				handleMouseMove(e);
			});

			target.current.addEventListener('mouseout', () => {
				handleMouseOut();
			});

			target.current.addEventListener('scroll', () => {
				handleScroll();
			});
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [target, active.current, ref.current]);

	const observerCallback = entries => {
		const [entry] = entries;
		if (entry.intersectionRatio < 1 && entry.intersectionRatio !== 0 && followCursor) {
			if (entry.boundingClientRect.top < 0) {
				tooltipDirection.current = 'bottom';
			}
			if (entry.boundingClientRect.bottom < 0) {
				tooltipDirection.current = 'top';
			}

			if (entry.boundingClientRect.left < 0) {
				tooltipDirection.current = 'right';
			}

			if (entry.boundingClientRect.right > window.innerWidth) {
				tooltipDirection.current = 'left';
			}
			setDirection(tooltipDirection.current);
		}
		if (active.current) {
			intersectionCheck.current = true;
		}
	};

	const handleMouseOver = () => {
		if (!active.current && ref.current) {
			active.current = true;
			ref.current.style.opacity = '1';
			ref.current.style.visibility = intersectionCheck.current ? 'visible' : 'hidden';
			setTooltipPosition();
		}
	};

	const handleMouseMove = event => {
		if (ref.current && active.current && followCursor) {
			cursor.current = { x: event.clientX, y: event.clientY };
			ref.current.style.visibility = intersectionCheck.current ? 'visible' : 'hidden';
			setTooltipPosition();
		}
	};

	const handleMouseOut = () => {
		if (ref.current) {
			hideTooltip();
		}
	};

	const handleScroll = () => {
		hideTooltip();
	};

	const hideTooltip = () => {
		ref.current.style.opacity = '0';
		ref.current.style.visibility = 'hidden';
		active.current = false;
	};

	const setTooltipPosition = () => {
		const rectTarget = target.current?.getBoundingClientRect();
		const rectTooltip = ref.current?.getBoundingClientRect();

		let positionX = 0;
		let positionY = 0;

		if (rectTarget && rectTooltip) {
			const tooltipSpace = 12;
			const tooltipHeight = Math.abs(rectTooltip.top - rectTooltip.bottom);
			const tooltipWidth = rectTooltip.right - rectTooltip.left;
			const targetHeight = Math.abs(rectTarget.top - rectTarget.bottom);
			const targetWidth = rectTarget.right - rectTarget.left;

			if (followCursor) {
				switch (tooltipDirection.current) {
					case 'top':
						positionX = `${cursor.current.x - tooltipWidth / 2}px`;
						positionY = `${cursor.current.y - tooltipHeight - tooltipSpace - 5}px`;
						break;
					case 'bottom':
						positionX = `${cursor.current.x - tooltipWidth / 2}px`;
						positionY = `${cursor.current.y + tooltipSpace + 9}px`;
						break;
					case 'left':
						positionX = `${cursor.current.x - tooltipWidth - tooltipSpace}px`;
						positionY = `${cursor.current.y - tooltipHeight / 2}px`;
						break;
					case 'right':
						positionX = `${cursor.current.x + tooltipSpace + 5}px`;
						positionY = `${cursor.current.y - tooltipHeight / 2}px`;
						break;
					default:
						break;
				}
			} else {
				switch (direction) {
					case 'top':
						positionX = `${rectTarget.right - targetWidth / 2 - tooltipWidth / 2}px`;
						positionY = `${rectTarget.top - tooltipHeight - tooltipSpace}px`;
						break;
					case 'bottom':
						positionX = `${rectTarget.right - targetWidth / 2 - tooltipWidth / 2}px`;
						positionY = `${rectTarget.bottom + tooltipSpace}px`;
						break;
					case 'left':
						positionX = `${rectTarget.left - tooltipWidth - tooltipSpace}px`;
						positionY = `${rectTarget.top - tooltipHeight / 2 + targetHeight / 2}px`;
						break;
					case 'right':
						positionX = `${rectTarget.right + tooltipSpace}px`;
						positionY = `${rectTarget.top - tooltipHeight / 2 + targetHeight / 2}px`;
						break;
					default:
						break;
				}
			}

			ref.current.style.left = positionX;
			ref.current.style.top = positionY;
		}
	};

	const getValue = () => {
		if (isArray(value) && value.length > 0) {
			return value;
		}

		if (value !== null && value !== '') {
			return value;
		}

		return '';
	};

	return ReactDOM.createPortal(
		<div
			ref={ref}
			className={styles.tooltip}
			data-place={direction}
			data-transparent={transparent}
			style={{ opacity: 0, visibility: 'hidden', position: 'absolute' }}
		>
			<div className={styles.value}>{getValue()}</div>
		</div>,
		tooltipContainer
	);
};

Tooltip.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
	place: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
	target: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	followCursor: PropTypes.bool,
	transparent: PropTypes.bool
};

Tooltip.defaultProps = {
	value: null,
	place: 'top',
	target: null,
	followCursor: false,
	transparent: false
};

export default Tooltip;
