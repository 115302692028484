import PropTypes from 'prop-types';
import React, { lazy, Suspense, useState } from 'react';
import CookieConsent from 'ViatoUi/CookieConsent';
import Loader from 'ViatoUi/Loader';
import { Anchor } from 'ViatoUi/Typo';

const PopupPrivacy = lazy(() => import('../PopupPrivacy'));

const CookieConsentHandler = ({ activePage }) => {
	const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

	return (
		<>
			<CookieConsent
				cookieName='CookieConsent'
				headline='Information zur Nutzung von Cookies'
				label='Verstanden'
				position={activePage === 'login' ? 'bottom-left' : 'bottom-right'}
			>
				Auf dieser Website nutzen wir ausschließlich systemrelevante Cookies, um unsere Dienste zu erbringen. Mit der Nutzung dieser
				Seite akzeptieren Sie diese essentiellen Cookies. Weitere Informationen hierzu finden Sie in unserer{' '}
				<Anchor onClick={() => setShowPrivacyPopup(!showPrivacyPopup)}>Datenschutzerklärung</Anchor>.
			</CookieConsent>
			{showPrivacyPopup && (
				<Suspense fallback={<Loader isPortal />}>
					<PopupPrivacy showPartners={!(activePage === 'login')} visible onClose={() => setShowPrivacyPopup(false)} />
				</Suspense>
			)}
		</>
	);
};

CookieConsentHandler.propTypes = {
	activePage: PropTypes.string
};

CookieConsentHandler.defaultProps = {
	activePage: 'login'
};

export default CookieConsentHandler;
