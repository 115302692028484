// extracted by mini-css-extract-plugin
var _1 = "#ff5a5f";
var _2 = "#fff";
var _3 = "#bd00ff";
var _4 = "#243048";
var _5 = "#bcc1d5";
var _6 = "#e50808";
var _7 = "#8e96ae";
var _8 = "#1097fe";
var _9 = "#1c5be3";
var _a = "#1bbedf";
var _b = "#5d6980";
var _c = "#635bff";
var _d = "#95c10e";
var _e = "#ffab00";
export { _1 as "airbnb", _2 as "bright", _3 as "critical", _4 as "dark", _5 as "darkSmoke", _6 as "error", _7 as "extraDarkSmoke", _8 as "info", _9 as "primary", _a as "secondary", _b as "silver", _c as "stripe", _d as "success", _e as "warning" }
