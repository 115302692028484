import { removeUnwantedWhitespaces } from 'Helper/stringHandler';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ButtonGhost from 'ViatoUi/FormElements/Buttons/ButtonGhostLogin';
import Button from 'ViatoUi/FormElements/Buttons/ButtonLogin';
import Input from 'ViatoUi/FormElements/Inputs/InputLogin';
import Spacer from 'ViatoUi/Layouts/Spacer';
import Alert from 'ViatoUi/Surfaces/AlertLogin';
import { Headline, Paragraph } from 'ViatoUi/Typo';

import { apiRequest, getApiRessource } from '../../../../_helper/apiRequest';

const FormRecoverPassword = ({ hidePasswordForm }) => {
	const [alert, setAlert] = useState({ message: '', time: Date.now(), type: 'error' });
	let username = '';

	const handleResetPassword = e => {
		e.preventDefault();

		apiRequest(
			getApiRessource('x/login', '_dev/login__action=reset_password.json'),
			`action=reset_password&username=${encodeURIComponent(removeUnwantedWhitespaces(username))}`
		).then(result => {
			if (Object.prototype.hasOwnProperty.call(result, 'error')) {
				// error received from backend
				if (Object.prototype.hasOwnProperty.call(result.error, 'code')) {
					// TODO: set correct error codes
					switch (result.error.code) {
						default:
							setAlert({
								message: `Es ist ein unbekannte Fehler aufgetreten. Bitte wenden Sie sich an den Viato Support: ${result.error.description}`,
								time: Date.now(),
								type: 'error'
							});
							break;
					}
				}

				// error received from apiRequest() due to invalid json response
				else {
					setAlert({
						message: 'Es ist ein technischer Fehler aufgetreten. Bitte wenden Sie sich an den Viato Support.',
						time: Date.now(),
						type: 'error'
					});
				}
			} else {
				setAlert({
					message: 'Ihnen wurde ein neues Passwort zugesendet, sofern die von Ihnen angegebene E-Mail-Adresse korrekt ist.',
					time: Date.now(),
					type: 'success'
				});
			}
		});
	};

	return (
		<form onSubmit={e => handleResetPassword(e)}>
			<Headline className='headline' type='h1'>
				Passwort vergessen?
			</Headline>
			<Paragraph className='password-info'>Bitte geben Sie die in Ihrem Nutzer-Account hinterlegte E-Mail-Adresse ein.</Paragraph>
			<Paragraph />
			<Input
				icon='fa-envelope'
				placeholder='E-Mail-Adresse'
				type='text'
				required
				onChange={val => {
					username = val;
				}}
			/>
			<Spacer height='l' />
			{alert.message.length ? (
				<Alert timestamp={alert.time} type={alert.type}>
					{alert.message}
				</Alert>
			) : (
				''
			)}
			<Spacer height='l' />
			<Button width='230px'>Passwort anfordern</Button>
			<Spacer height='m' />
			<ButtonGhost
				onClick={() => {
					hidePasswordForm();
				}}
			>
				Zurück zum Login
			</ButtonGhost>
		</form>
	);
};

FormRecoverPassword.propTypes = {
	hidePasswordForm: PropTypes.func
};

FormRecoverPassword.defaultProps = {
	hidePasswordForm: () => {}
};

export default FormRecoverPassword;
