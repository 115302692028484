import PropTypes from 'prop-types';
import React from 'react';
import Constants from 'Shared/constants';

import * as styles from './Spacer.module.scss';

const Spacer = ({ display, height, width }) => {
	const autoDisplay = width !== 'none' && height === 'none' ? 'inline-block' : 'block';

	return (
		<span className={styles.spacer} data-display={display === 'auto' ? autoDisplay : display} data-height={height} data-width={width} />
	);
};

Spacer.propTypes = {
	width: PropTypes.oneOf([...Constants.SIZES, 'none']),
	height: PropTypes.oneOf([...Constants.SIZES, 'none']),
	display: PropTypes.oneOf(['auto', 'block', 'inline-block', 'inline'])
};

Spacer.defaultProps = {
	width: 'xs',
	height: 'none',
	display: 'auto'
};

export default Spacer;
