import './InputLogin.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import OutsideEventHandler from 'ViatoUi/Utils/OutsideEventHandler';

const InputLogin = ({ disabled, icon, onChange, placeholder, required, type }) => {
	const [value, setValue] = useState('');
	const [focused, setFocused] = useState(false);
	const [inputType, setInputType] = useState(type);

	const inputRef = useRef(null);

	useEffect(() => {
		onChange(value);

		// Workaround for ChromE autofill
		// on autofill a css animation is fired (see css file). With this we can
		// check if the user is using autofill and if so set the focused state to true.
		// ... someone please kill me
		inputRef.current.addEventListener('animationstart', e => {
			if (e.animationName === 'autofill') {
				setFocused(true);
			}
		});
	}, [value]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (inputRef.current) {
				setValue(inputRef.current.value);
				if (inputRef.current.value !== '') {
					setFocused(true);
				}
				clearInterval(interval);
			}
		}, 100);
	});

	return (
		<OutsideEventHandler
			callback={() => {
				if (!value.length) {
					setFocused(false);
				}
			}}
		>
			<div className='react__login-input' data-disabled={disabled} data-focused={focused}>
				<i className={`label-icon fas ${icon}`} />
				<div className='label'>{placeholder}</div>
				<input
					ref={inputRef}
					required={required}
					type={inputType}
					value={value}
					onBlur={e => {
						if (String(e.target.value) === '') {
							setFocused(false);
						} else {
							setFocused(true);
						}
						setValue(e.target.value);
					}}
					onChange={e => {
						if (String(e.target.value) === '') {
							setFocused(false);
						} else {
							setFocused(true);
						}
						setValue(e.target.value);
					}}
					onFocus={() => {
						setFocused(true);
					}}
				/>
				{type === 'password' && (
					<i
						className={`toggle-type fas ${inputType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}
						role='none'
						onClick={() => {
							if (inputType === 'password') {
								setInputType('text');
							} else {
								setInputType('password');
							}
						}}
					/>
				)}
			</div>
		</OutsideEventHandler>
	);
};

InputLogin.propTypes = {
	type: PropTypes.oneOf(['text', 'password', 'number']),
	placeholder: PropTypes.string,
	icon: PropTypes.string,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool
};

InputLogin.defaultProps = {
	type: 'text',
	placeholder: '',
	icon: 'fa-pencil',
	required: false,
	onChange: () => {},
	disabled: false
};

export default InputLogin;
