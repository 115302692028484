import './Loader.scss';

import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

const Loader = ({ className, fadeIn, iconOnly, isPortal }) => {
	if (isPortal) {
		return ReactDOM.createPortal(
			<div className={`react__loader loading ${className}`} data-fade-in={fadeIn} data-icon-only={iconOnly}>
				<div className='bars'>
					<div className='loadingBar' />
					<div className='loadingBar' />
					<div className='loadingBar' />
					<div className='loadingBar' />
					<div className='loadingBar' />
				</div>
				<div className='text' />
			</div>,
			document.getElementById('react__full-page-dimmers')
		);
	}

	return (
		<div className={`react__loader loading ${className}`} data-fade-in={fadeIn} data-icon-only={iconOnly}>
			<div className='bars'>
				<div className='loadingBar' />
				<div className='loadingBar' />
				<div className='loadingBar' />
				<div className='loadingBar' />
				<div className='loadingBar' />
			</div>
			<div className='text' />
		</div>
	);
};

Loader.propTypes = {
	fadeIn: PropTypes.bool,
	iconOnly: PropTypes.bool,
	className: PropTypes.string,
	isPortal: PropTypes.bool
};

Loader.defaultProps = {
	fadeIn: true,
	iconOnly: false,
	className: '',
	isPortal: false
};

export default Loader;
