import './CookieConsent.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useCookies } from 'react-cookie';

import Button from '../FormElements/Buttons/Button';
import { FlexBox } from '../Layouts/Flex';
import { Headline, Paragraph } from '../Typo';

const CookieConsent = ({ children, cookieName = 'cookieConsent', headline, label = 'Akzeptieren', position = 'bottom-left' }) => {
	const [cookies, setCookie] = useCookies([cookieName]);
	const expires = new Date();
	expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
	function onClick() {
		setCookie(cookieName, 'true', { path: '/', expires, sameSite: 'strict' });
	}

	if (typeof cookies[cookieName] === 'undefined') {
		return (
			<div className='react__cookie-consent' data-position={position}>
				<span className='consent-headline'>
					<Headline type='h5'>{headline}</Headline>
				</span>
				<span className='consent-text'>
					<Paragraph>{children}</Paragraph>
				</span>
				<FlexBox className='consent-buttons' justifyContent='flex-end'>
					<Button label={label} type='primary' onClick={() => onClick()} />
				</FlexBox>
			</div>
		);
	}

	return null;
};

CookieConsent.propTypes = {
	cookieName: PropTypes.string,
	position: PropTypes.oneOf(['bottom-left', 'bottom-right', 'top-left', 'top-right']),
	headline: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	label: PropTypes.string
};

export default CookieConsent;
