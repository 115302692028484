import './ContentRight.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Button from 'ViatoUi/FormElements/Buttons/ButtonLogin';
import { Headline, Paragraph } from 'ViatoUi/Typo';

const ContentRight = ({ callback }) => {
	return (
		<div className='area-no-bg-right'>
			<div className='content'>
				<Headline type='h1'>Bereits einen Account erstellt?</Headline>
				<Headline className='subline' type='h6'>
					Dann können Sie sich im Loginbereich direkt mit Ihren Daten anmelden.
				</Headline>
				<Paragraph />
				<Paragraph />
				<Button type='secondary' width='185px' onClick={() => callback()}>
					Log In
				</Button>
			</div>
		</div>
	);
};

ContentRight.propTypes = {
	callback: PropTypes.func
};

ContentRight.defaultProps = {
	callback: () => {}
};

export default ContentRight;
