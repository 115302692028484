import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const OutsideEventHandler = ({ callback, children, className, eventType, style, triggerRef }) => {
	const ref = useRef(null);
	const handleEventOutside = event => {
		if (
			ref.current &&
			!ref.current.contains(event.target) &&
			(triggerRef === null || (triggerRef.current && !triggerRef.current.contains(event.target)))
		) {
			callback(event);
		}
	};

	useEffect(() => {
		document.addEventListener(eventType, handleEventOutside);
		return () => {
			document.removeEventListener(eventType, handleEventOutside);
		};
	});

	return (
		<div ref={ref} className={className} style={style}>
			{children}
		</div>
	);
};

OutsideEventHandler.propTypes = {
	eventType: PropTypes.string,
	callback: PropTypes.func,
	style: PropTypes.object,
	triggerRef: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};

OutsideEventHandler.defaultProps = {
	eventType: 'click',
	callback: () => {},
	style: {},
	triggerRef: null,
	className: 'OutsideEventHandler'
};

export default OutsideEventHandler;
