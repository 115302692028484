import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFlexBox = styled.div(
	{},
	({ $alignContent, $alignItems, $disabled, $display, $flexDirection, $flexWrap, $gap, $justifyContent, style }) => ({
		style,
		alignItems: $alignItems,
		display: $display,
		flexDirection: $flexDirection,
		flexWrap: $flexWrap,
		gap: $gap,
		justifyContent: $justifyContent,
		alignContent: $alignContent,
		opacity: $disabled ? 0.4 : 1
	})
);

StyledFlexBox.propTypes = {
	$display: PropTypes.oneOf(['flex', 'inline-flex']),
	$flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
	$flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
	$justifyContent: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly',
		'start',
		'end',
		'left',
		'right'
	]),
	$alignItems: PropTypes.oneOf(['stretch', 'flex-start', 'flex-end', 'center', 'baseline']),
	$alignContent: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly',
		'stretch',
		'start',
		'end',
		'baseline'
	]),
	$gap: PropTypes.string,
	style: PropTypes.object,
	$disabled: PropTypes.bool
};

StyledFlexBox.defaultProps = {
	$display: 'flex',
	style: {},
	$flexDirection: 'row',
	$gap: '0',
	$flexWrap: 'nowrap',
	$justifyContent: 'flex-start',
	$alignItems: 'flex-start',
	$alignContent: 'flex-start',
	$disabled: false
};

export default StyledFlexBox;
