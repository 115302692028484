const isDevServer = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const forceDev = (urlParams.get('dev') || 'false') === 'true';

	return window.location.href.includes('.dev.') || forceDev;
};

const isDevBuild = () => {
	return process.env.NODE_ENV !== 'production';
};

const shouldUseLogger = () => {
	return !process.env.ENV_OPT.split(',').includes('no-log');
};

const getServerUrl = () => {
	return isDevServer() ? `https://${window.location.hostname}/` : `https://suite.viato.net/`;
};

const isV3Proxy = () => {
	return !window.location.hostname.includes('viato.net');
};

const getCdnPath = (file = '', useThumbnail = true) => {
	let fileName = file;
	let cdnPath = '/crs/p/pub/';

	if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
		cdnPath = '/crs/op/';

		if (useThumbnail) {
			fileName = fileName.replaceAll('.jpeg', '.s.jpeg').replaceAll('.jpg', '.s.jpg');
		}
	}

	return isDevServer()
		? `https://${window.location.hostname}/p/media_explorer/pub/${file}`
		: `https://cdn.viato.net${cdnPath}${fileName}`;
};

export { isDevServer, isDevBuild, getServerUrl, getCdnPath, isV3Proxy, shouldUseLogger };
