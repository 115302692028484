import isEqual from 'lodash-es/isEqual';
import { useEffect, useRef, useState } from 'react';

const useFirstRender = (value = null) => {
	const firstRender = useRef(true);
	const [initialValue] = useState(value);
	const [touched, setTouched] = useState(false);

	useEffect(() => {
		firstRender.current = false;
	}, []);

	useEffect(() => {
		if (!isEqual(value, initialValue) && !touched) {
			setTouched(true);
		}
	}, [value]);

	if (value === null) {
		return firstRender.current;
	}

	return !touched;
};

export default useFirstRender;
