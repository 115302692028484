import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Tooltip from 'ViatoUi/DataDisplay/Tooltip';

import StyledFlexBox from './StyledFlexBox';

const FlexBox = React.forwardRef(
	(
		{
			alignContent = 'flex-start',
			alignItems = 'flex-start',
			children = null,
			className = '',
			data = {},
			disabled = false,
			display = 'flex',
			flexDirection = 'row',
			flexWrap = 'nowrap',
			gap = '0',
			id = null,
			justifyContent = 'flex-start',
			onClick = () => {},
			onContextMenu = () => {},
			onDragEnter = () => {},
			onDragLeave = () => {},
			onDrop = () => {},
			onKeyDown = () => {},
			onMouseEnter = () => {},
			onMouseLeave = () => {},
			style = {},
			tooltip = [],
			tooltipConfig = {
				transparent: false,
				followCursor: true,
				place: 'top'
			}
		},
		ref
	) => {
		const elementRef = useRef(null);

		const onClickHandler = e => {
			onClick(e);
		};

		const onContextMenuHandler = e => {
			onContextMenu(e);
		};

		const onMouseLeaveHandler = e => {
			onMouseLeave(e);
		};

		return (
			<>
				<StyledFlexBox
					ref={ref || elementRef}
					$alignContent={alignContent}
					$alignItems={alignItems}
					$disabled={disabled || data['data-disabled']}
					$display={display}
					$flexDirection={flexDirection}
					$flexWrap={flexWrap}
					$gap={gap}
					$justifyContent={justifyContent}
					className={`react__flex-box ${className}`}
					id={id}
					role='listbox'
					style={style}
					tabIndex={-1}
					onClick={e => (!disabled ? onClickHandler(e) : {})}
					onContextMenu={e => (!disabled ? onContextMenuHandler(e) : {})}
					onDragEnter={e => (!disabled ? onDragEnter(e) : {})}
					onDragLeave={e => (!disabled ? onDragLeave(e) : {})}
					onDrop={e => (!disabled ? onDrop(e) : {})}
					onKeyDown={e => (!disabled ? onKeyDown(e) : {})}
					onMouseEnter={e => (!disabled ? onMouseEnter(e) : {})}
					onMouseLeave={e => (!disabled ? onMouseLeaveHandler(e) : {})}
					{...data}
				>
					{children}
				</StyledFlexBox>
				{tooltip && tooltip.length > 0 && (
					<Tooltip
						followCursor={tooltipConfig.followCursor || true}
						place={tooltipConfig.place || 'top'}
						target={ref || elementRef}
						transparent={tooltipConfig.transparent || false}
						value={tooltip}
					/>
				)}
			</>
		);
	}
);

FlexBox.propTypes = {
	tooltipConfig: PropTypes.shape({
		transparent: PropTypes.bool,
		followCursor: PropTypes.bool,
		place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
	}),
	display: PropTypes.oneOf(['flex', 'inline-flex']),
	flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
	flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
	justifyContent: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly',
		'start',
		'end',
		'left',
		'right'
	]),
	alignItems: PropTypes.oneOf(['stretch', 'flex-start', 'flex-end', 'center', 'baseline']),
	alignContent: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly',
		'stretch',
		'start',
		'end',
		'baseline'
	]),
	gap: PropTypes.string,
	className: PropTypes.string,
	data: PropTypes.object,
	onClick: PropTypes.func,
	style: PropTypes.object,
	onContextMenu: PropTypes.func,
	onDragEnter: PropTypes.func,
	onDragLeave: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onKeyDown: PropTypes.func,
	onDrop: PropTypes.func,
	children: PropTypes.node,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default FlexBox;
