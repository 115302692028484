import './Login.scss';
import '../../../scss/_fonts.scss';

import { CookieConsentHandler } from 'Components/DataProtection';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Loader from 'ViatoUi/Loader';
import useFirstRender from 'ViatoUi/Utils/useFirstRender';

import { SvgFlower } from '../../../p/viato/login/flower.svg';
import ContentCenter from './ContentCenter';
import ContentLeft from './ContentLeft';
import ContentRight from './ContentRight';

const PopupImprint = lazy(() => import('../DataProtection/PopupImprint'));
const PopupPrivacy = lazy(() => import('../DataProtection/PopupPrivacy'));

const Login = () => {
	const firstRender = useFirstRender();

	const registrationEnabled = false;
	const [activeDataProtectionPopup, setActiveDataProtectionPopup] = useState(false);
	const [initTransition, setInitTransition] = useState(false);
	const [animationSettings, setAnimationSettings] = useState({
		classes: {
			slideToLeft: false,
			slideToLeftDone: false,
			slideToRight: false,
			slideToRightDone: false
		},
		activeForm: 'login'
	});

	const togglePageSwapTransition = () => {
		setAnimationSettings({
			classes: {
				slideToLeft: true,
				slideToLeftDone: false,
				slideToRight: false,
				slideToRightDone: false
			},
			activeForm: 'login'
		});

		setTimeout(() => {
			setAnimationSettings({
				classes: {
					slideToLeft: true,
					slideToLeftDone: true,
					slideToRight: false,
					slideToRightDone: false
				},
				activeForm: 'registration'
			});
		}, 850);
	};

	const toggleFormSwapTransition = () => {
		setAnimationSettings({
			classes: {
				slideToLeft: false,
				slideToLeftDone: true,
				slideToRight: true,
				slideToRightDone: false
			},
			activeForm: 'registration'
		});

		setTimeout(() => {
			setAnimationSettings({
				classes: {
					slideToLeft: false,
					slideToLeftDone: false,
					slideToRight: false,
					slideToRightDone: true
				},
				activeForm: 'login'
			});

			setTimeout(() => {
				setAnimationSettings({
					classes: {
						slideToLeft: false,
						slideToLeftDone: false,
						slideToRight: false,
						slideToRightDone: false
					},
					activeForm: 'login'
				});
			}, 150);
		}, 850);
	};

	useEffect(() => {
		if (!firstRender) {
			if (initTransition) {
				togglePageSwapTransition();
			} else {
				toggleFormSwapTransition();
			}
		}
	}, [initTransition]);

	return (
		<div
			className='login'
			data-slide-to-left={animationSettings.classes.slideToLeft}
			data-slide-to-left-done={animationSettings.classes.slideToLeftDone}
			data-slide-to-right={animationSettings.classes.slideToRight}
			data-slide-to-right-done={animationSettings.classes.slideToRightDone}
		>
			<div className='slider'>
				<ContentLeft callback={() => setInitTransition(!initTransition)} enabled={registrationEnabled} />
				<ContentCenter
					activeForm={animationSettings.activeForm}
					onDataProtectionClick={target => setActiveDataProtectionPopup(target)}
				/>
				{registrationEnabled && <ContentRight callback={() => setInitTransition(!initTransition)} />}
			</div>
			<div className='flower'>
				<SvgFlower />
			</div>

			<CookieConsentHandler activePage='login' />

			{activeDataProtectionPopup === 'privacy' && (
				<Suspense fallback={<Loader isPortal />}>
					<PopupPrivacy showPartners={false} visible onClose={() => setActiveDataProtectionPopup(false)} />
				</Suspense>
			)}

			{activeDataProtectionPopup === 'imprint' && (
				<Suspense fallback={<Loader isPortal />}>
					<PopupImprint visible onClose={() => setActiveDataProtectionPopup(false)} />
				</Suspense>
			)}
		</div>
	);
};

export default Login;
