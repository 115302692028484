import './Paragraph.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Constants from 'Shared/constants';

const Paragraph = ({ children, className, color, font, isDiv, isItalic, isSpan, isStrong, noBreak, style }) => {
	if (isSpan) {
		return (
			<span
				className={`react__paragraph ${className}`}
				data-color={color}
				data-font={font}
				data-italic={isItalic}
				data-no-break={noBreak}
				data-strong={isStrong}
				style={style}
			>
				{children}
			</span>
		);
	}

	if (isDiv) {
		return (
			<div
				className={`react__paragraph ${className}`}
				data-color={color}
				data-font={font}
				data-italic={isItalic}
				data-no-break={noBreak}
				data-strong={isStrong}
				style={style}
			>
				{children}
			</div>
		);
	}

	return (
		<p
			className={`react__paragraph ${className}`}
			data-color={color}
			data-font={font}
			data-italic={isItalic}
			data-no-break={noBreak}
			data-strong={isStrong}
			style={style}
		>
			{children}
		</p>
	);
};

Paragraph.propTypes = {
	font: PropTypes.oneOf([...Constants.PARAGRAPHS, 'inherit']),
	color: PropTypes.oneOf([...Constants.TYPES, 'inherit']),
	children: PropTypes.node,
	className: PropTypes.string,
	noBreak: PropTypes.bool,
	isItalic: PropTypes.bool,
	isStrong: PropTypes.bool,
	isDiv: PropTypes.bool,
	isSpan: PropTypes.bool,
	style: PropTypes.object
};

Paragraph.defaultProps = {
	font: 'small',
	className: '',
	children: null,
	noBreak: false,
	color: 'inherit',
	isItalic: false,
	isStrong: false,
	isDiv: false,
	isSpan: false,
	style: {}
};

export default Paragraph;
