// extracted by mini-css-extract-plugin
var _1 = "#ff7e82";
var _2 = "#e8ebf2";
var _3 = "#d14dff";
var _4 = "#5d6980";
var _5 = "#d0d6e4";
var _6 = "#f93636";
var _7 = "#bcc1d5";
var _8 = "#54b5ff";
var _9 = "#3e7cff";
var _a = "#3fd4f2";
var _b = "#8e96ae";
var _c = "#7c75fd";
var _d = "#add438";
var _e = "#ffc234";
export { _1 as "airbnb", _2 as "bright", _3 as "critical", _4 as "dark", _5 as "darkSmoke", _6 as "error", _7 as "extraDarkSmoke", _8 as "info", _9 as "primary", _a as "secondary", _b as "silver", _c as "stripe", _d as "success", _e as "warning" }
