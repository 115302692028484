import { apiRequest } from 'Helper/apiRequest';
import { removeUnwantedWhitespaces } from 'Helper/stringHandler';
import { getSearchParamsAsString } from 'Helper/urlHelpers';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Cookies } from 'react-cookie';
import ButtonGhost from 'ViatoUi/FormElements/Buttons/ButtonGhostLogin';
import Button from 'ViatoUi/FormElements/Buttons/ButtonLogin';
import Input from 'ViatoUi/FormElements/Inputs/InputLogin';
import Spacer from 'ViatoUi/Layouts/Spacer';
import Loader from 'ViatoUi/Loader';
import AlertSuite from 'ViatoUi/Surfaces/Alert';
import Alert from 'ViatoUi/Surfaces/AlertLogin';
import { Headline, Paragraph } from 'ViatoUi/Typo';

const FormLogin = ({ showPasswordForm }) => {
	const cookies = new Cookies();
	const [username, setUsername] = useState('');
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [password, setPassword] = useState('');
	const [otp, setOtp] = useState('');
	const [loginLoading, setLoginLoading] = useState(false);

	const getErrorMessage = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const sessionFailureInfo = urlParams.get('session_failure');

		if (sessionFailureInfo === 'timeout') {
			return 'Sie wurden automatisch ausgeloggt, da Sie zu lange inaktiv waren.';
		}

		if (sessionFailureInfo === 'invalid_session') {
			return 'Sie wurden automatisch ausgeloggt, da Ihr Nutzer sich an einem anderen Gerät oder Browser angemeldet hat.';
		}

		if (sessionFailureInfo === 'missing_session_cookie') {
			return 'Sie wurden ausgeloggt, da entweder Ihre Sitzung ausgelaufen ist, oder Sie Ihre Browser Cookies gelöscht haben.';
		}

		if (sessionFailureInfo === 'account_suspended') {
			return 'Ihr Account wurde gesperrt. Bitte wenden Sie sich an den Viato Support.';
		}

		if (sessionFailureInfo === 'contract_suspended') {
			return 'Ihr Vertrag wurde gesperrt. Bitte wenden Sie sich an den Viato Support.';
		}

		if (sessionFailureInfo) {
			return 'Sie haben keinen Zugriff auf diesen Bereich.';
		}

		return '';
	};

	const [error, setError] = useState({
		message: getErrorMessage(),
		time: Date.now()
	});

	const loginUser = (session, urlParam = '') => {
		const expires = new Date();
		expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
		cookies.set('SESSION', session, { path: '/', expires, sameSite: 'strict' });
		setTimeout(() => {
			let redirectUrl = `/core${getSearchParamsAsString({ filterSessionParams: true })}`;
			if (urlParam && urlParam.length) {
				const urlChainer = redirectUrl.includes('?') ? '&' : '?';
				redirectUrl = `${redirectUrl}${urlChainer}${urlParam}`;
			}
			window.location.href = redirectUrl;
		}, 11); // Workaround fo this react bug: https://stackoverflow.com/a/76945127
	};

	const handleLogIn = e => {
		e.preventDefault();
		setLoginLoading(true);
		setError({
			message: '',
			time: Date.now()
		});

		apiRequest(
			`x/login?action=login&username=${encodeURIComponent(removeUnwantedWhitespaces(username))}&password=${encodeURIComponent(
				removeUnwantedWhitespaces(password)
			)}${showOtpModal ? `&otp=${encodeURIComponent(removeUnwantedWhitespaces(otp))}` : ''}`,
			'',
			'GET'
		).then(result => {
			if (Object.prototype.hasOwnProperty.call(result, 'error')) {
				setLoginLoading(false);
				setShowOtpModal(false);

				// error received from backend
				if (Object.prototype.hasOwnProperty.call(result.error, 'code')) {
					// TODO: set correct error codes
					switch (result.error.code) {
						case 100:
							setError({ message: 'Benutzername oder Kennwort falsch', time: Date.now() });
							break;

						case 101:
							setError({ message: 'Sie sind nicht eingeloggt.', time: Date.now() });
							break;

						case 102:
							setError({
								message: `Ihr Benutzer wurde gesperrt. ${
									result.error.additionalInformation?.adminName
										? `Bitte wenden Sie sich an Ihren Administrator ${result.error.additionalInformation.adminName}. Nur dieser kann Ihren Benutzerzugang wieder freischalten.`
										: ''
								} Bei weiteren Fragen steht Ihnen das Viato Support-Team gerne zur Verfügung.`,
								time: Date.now()
							});
							break;

						case 103:
							setError({
								message: 'Ihr Vertrag wurde gesperrt. Bitte wenden Sie sich an den Viato Support.',
								time: Date.now()
							});
							break;

						case 104: {
							setShowOtpModal(true);
							break;
						}

						case 105:
							setError({
								message:
									'Der Sicherheitscode konnte nicht versendet werden. Bitte versuchen Sie es erneut. Sollte der Fehler weiterhin bestehen, kontaktieren Sie bitte den Viato Support.',
								time: Date.now()
							});
							break;

						default:
							setError({
								message: `Es ist ein unbekannte Fehler aufgetreten. Bitte wenden Sie sich an den Viato Support: ${result.error.description}`,
								time: Date.now()
							});
							break;
					}
				}

				// error received from apiRequest() due to invalid json response
				else {
					setError({
						message: 'Es ist ein technischer Fehler aufgetreten. Bitte wenden Sie sich an den Viato Support.',
						time: Date.now()
					});
				}
			} else {
				loginUser(result.success.session);
			}
		});
	};

	return (
		<form onSubmit={e => handleLogIn(e)}>
			{loginLoading && <Loader />}
			<Headline className='headline' type='h1'>
				Anmelden
			</Headline>
			{!showOtpModal && (
				<>
					<Input icon='fa-user' placeholder='Benutzername' type='text' required onChange={val => setUsername(val)} />
					<Paragraph font='large' />
					<Input icon='fa-key' placeholder='Passwort' type='password' required onChange={val => setPassword(val)} />
				</>
			)}
			{showOtpModal && (
				<>
					<Paragraph font='large' />
					<div style={{ width: '500px', maxWidth: '90%' }}>
						Im Rahmen unserer Bemühungen, die Sicherheit Ihrer Daten zu gewährleisten, haben wir eine zusätzliche
						Sicherheitsmaßnahme beim Login in die Viato Suite eingeführt. Wir haben Ihnen soeben an die für Ihren Benutzer
						hinterlegte E-Mail-Addresse einen Sicherheitscode gesendet.
						<br />
						<br />
						<AlertSuite type='info'>
							Bitte <strong>prüfen Sie Ihr E-Mail Postfach</strong> und geben Sie den{' '}
							<strong>5-stelligen Sicherheitscode</strong> in das folgende Eingabefeld ein, um mit dem Loginvorgang fortfahren
							zu können. Bitte wenden Sie sich an den Viato Support, falls Sie keinen Sicherheitscode erhalten haben.
						</AlertSuite>
					</div>
					<Paragraph font='large' />
					<Input
						icon='fa-unlock-alt'
						placeholder='Sicherheitscode'
						type='number'
						required
						onChange={val => {
							setOtp(val);
						}}
					/>
				</>
			)}

			<Spacer height='l' />
			{error.message.length ? <Alert timestamp={error.time}>{error.message}</Alert> : ''}
			<Spacer height='l' />
			<Button disabled={loginLoading} width='210px'>
				Log In
			</Button>
			<Spacer height='m' />
			<ButtonGhost
				onClick={() => {
					showPasswordForm();
				}}
			>
				Passwort vergessen?
			</ButtonGhost>
		</form>
	);
};

FormLogin.propTypes = {
	showPasswordForm: PropTypes.func
};

FormLogin.defaultProps = {
	showPasswordForm: () => {}
};

export default FormLogin;
