import { getSearchParamsAsString } from './urlHelpers';

export const getApiRessource = liveFile => {
	return liveFile;
};

export const apiRequest = async (
	url = '',
	body = '',
	method = 'POST',
	headers = {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
) => {
	const options = method === 'GET' ? { method } : { method, headers, body };
	const response = await fetch(url, options);
	const text = await response.text();

	try {
		const data = JSON.parse(text);

		if (Object.prototype.hasOwnProperty.call(data, 'session_failure')) {
			document.location.href = `/?session_failure=${data.session_failure}${getSearchParamsAsString({ prefix: '&', filterSessionParams: true })}`;
		} else {
			return data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		// eslint-disable-next-line no-console
		console.log({ url, params: body, response: text });

		const div = document.createElement('div');
		div.innerHTML = text;
		const errorMessage = div.textContent || div.innerText || text;
		return { error: { description: errorMessage.replaceAll('\n', ' '), code: 500, additionalInformation: err.toString() } };
	}

	return false;
};

export default apiRequest;
