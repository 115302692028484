const getSearchParamsAsString = ({ additionalParamsToFilter = [], filterSessionParams = false, prefix = '?' }) => {
	let searchParams = prefix;
	const urlParams = new URLSearchParams(window.location.search);

	const sessionParams = ['session_failure', 'sessionId', 'userId'];

	urlParams.forEach((value, param) => {
		if ((!filterSessionParams || !sessionParams.includes(param)) && !additionalParamsToFilter.includes(param)) {
			searchParams = `${searchParams}${searchParams !== prefix ? '&' : ''}${param}=${value}`;
		}
	});

	if (searchParams !== prefix) {
		return searchParams;
	}

	return '';
};

const removeUrlParams = (paramsToRemove = []) => {
	const pageUrl = new URL(window.location);
	const urlParams = new URLSearchParams(window.location.search);
	const urlParamsAsArray = [];
	urlParams.forEach((value, param) => {
		urlParamsAsArray.push(param);
	});

	const toRemove = paramsToRemove.length > 0 ? paramsToRemove : urlParamsAsArray;

	toRemove.forEach(param => {
		pageUrl.searchParams.delete(param);
	});

	window.history.pushState({}, '', pageUrl);
};

const hasSuperSecretAccess = userRole => {
	const urlParams = new URLSearchParams(window.location.search);
	const superSecretAdminSettings = urlParams.get('superSecretAdminSettings');
	const version = urlParams.get('version');

	return userRole === 'viato' && superSecretAdminSettings === 'enabled' && version === 'pro';
};

export { getSearchParamsAsString, removeUrlParams, hasSuperSecretAccess };
