import './ButtonGhostLogin.scss';

import PropTypes from 'prop-types';
import React from 'react';

const ButtonGhostLogin = props => {
	const { children, onClick } = props;
	return (
		<div className='react__login-btn-ghost' role='button' tabIndex={-1} onClick={onClick}>
			{children}
		</div>
	);
};

ButtonGhostLogin.propTypes = {
	onClick: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired
};

export default ButtonGhostLogin;
