import React from 'react';
import Button from 'ViatoUi/FormElements/Buttons/ButtonLogin';
import Input from 'ViatoUi/FormElements/Inputs/InputLogin';
import Spacer from 'ViatoUi/Layouts/Spacer';
import { Headline, Paragraph } from 'ViatoUi/Typo';

const FormRegister = () => {
	return (
		<form onSubmit={() => {}}>
			<Headline className='headline' type='h1'>
				Account erstellen
			</Headline>
			<Input icon='fa-user' placeholder='Name' type='text' />
			<Paragraph />
			<Input icon='fa-user' placeholder='Vorname' type='text' />
			<Paragraph />
			<Input icon='fa-envelope' placeholder='E-Mail' type='text' />
			<Spacer height='l' />
			<Spacer height='l' />
			<Button width='210px'>Jetzt erstellen</Button>
		</form>
	);
};

export default FormRegister;
