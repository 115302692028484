import './Anchor.scss';

import PropTypes from 'prop-types';
import React from 'react';

const Anchor = ({ children, className, href, onClick, target }) => {
	return (
		<a className={`react__anchor ${className}`} href={href} target={target} onClick={e => onClick(e)}>
			{children}
		</a>
	);
};

Anchor.propTypes = {
	target: PropTypes.oneOf(['_self', '_blank']),
	href: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
	className: PropTypes.string
};

Anchor.defaultProps = {
	target: '_self',
	href: '#',
	onClick: () => {},
	className: '',
	children: null
};

export default Anchor;
