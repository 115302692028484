import './ContentCenter.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { SvgWaveLeft } from '../../../../p/viato/login/wave-left.svg';
import { SvgWaveLeftShadow } from '../../../../p/viato/login/wave-left-shadow.svg';
import { SvgWaveRight } from '../../../../p/viato/login/wave-right.svg';
import { SvgWaveRightShadow } from '../../../../p/viato/login/wave-right-shadow.svg';
import Forms from '../Forms';

const ContentCenter = ({ activeForm, onDataProtectionClick }) => {
	return (
		<div className='area-white'>
			<div className='wave-left'>
				<SvgWaveLeft />
				<SvgWaveLeftShadow />
			</div>
			<div className='content'>
				<Forms activeForm={activeForm} onDataProtectionClick={target => onDataProtectionClick(target)} />
			</div>
			<div className='wave-right'>
				<SvgWaveRight />
				<SvgWaveRightShadow />
			</div>
		</div>
	);
};

ContentCenter.propTypes = {
	activeForm: PropTypes.string.isRequired,
	onDataProtectionClick: PropTypes.func
};

ContentCenter.defaultProps = {
	onDataProtectionClick: () => {}
};

export default ContentCenter;
