import './Forms.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Logo } from '../../../../p/viato/viato-logo-2020.svg';
import FormLogin from './FormLogin';
import FormRecoverPassword from './FormRecoverPassword';
import FormRegister from './FormRegister';

const Forms = ({ activeForm, onDataProtectionClick }) => {
	const didMount = useRef(false);
	const [showPasswordForm, setShowPasswordForm] = useState(false);
	const [animationSettings, setAnimationSettings] = useState({
		classes: {
			fadeOut: false,
			fadeIn: false
		},
		activeForm: 'login'
	});

	const getContent = () => {
		if (activeForm !== 'login') {
			return <FormRegister />;
		}

		if (animationSettings.activeForm === 'recoverPassword') {
			return (
				<FormRecoverPassword
					hidePasswordForm={() => {
						setShowPasswordForm(false);
					}}
				/>
			);
		}

		return (
			<FormLogin
				showPasswordForm={() => {
					setShowPasswordForm(true);
				}}
			/>
		);
	};

	useEffect(() => {
		if (didMount.current) {
			setAnimationSettings({
				classes: {
					fadeOut: true,
					fadeIn: false
				},
				activeForm: showPasswordForm ? 'login' : 'recoverPassword'
			});
			setTimeout(() => {
				setAnimationSettings({
					classes: {
						fadeOut: false,
						fadeIn: true
					},
					activeForm: showPasswordForm ? 'recoverPassword' : 'login'
				});
				setTimeout(() => {
					setAnimationSettings({
						classes: {
							fadeOut: false,
							fadeIn: false
						},
						activeForm: showPasswordForm ? 'recoverPassword' : 'login'
					});
				}, 150);
			}, 450);
		} else {
			didMount.current = true;
		}
	}, [showPasswordForm]);

	return (
		<div className='form' data-fade-in={animationSettings.classes.fadeIn} data-fade-out={animationSettings.classes.fadeOut}>
			{getContent()}

			<div className='footer-nav'>
				<div className='item' role='button' tabIndex={0} onClick={() => onDataProtectionClick('privacy')}>
					Datenschutz
				</div>
				<div className='item' role='button' tabIndex={0} onClick={() => onDataProtectionClick('imprint')}>
					Impressum
				</div>
				<div className='logo'>
					<Logo />
				</div>
			</div>
		</div>
	);
};

Forms.propTypes = {
	onDataProtectionClick: PropTypes.func,
	activeForm: PropTypes.string
};

Forms.defaultProps = {
	onDataProtectionClick: () => {},
	activeForm: 'login'
};

export default Forms;
