import './AlertLogin.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const AlertLogin = ({ children, timestamp, type }) => {
	const didMount = useRef(false);
	const [animationSettings, setAnimationSettings] = useState({
		fadeIn: false,
		update: false
	});

	useEffect(() => {
		if (didMount.current) {
			setAnimationSettings({ fadeIn: true, update: true });
			setTimeout(() => {
				setAnimationSettings({ fadeIn: true, update: false });
			}, 300);
		} else {
			didMount.current = true;
			setTimeout(() => {
				setAnimationSettings({ fadeIn: true, update: false });
			}, 100);
		}
	}, [timestamp]);

	return (
		<div className='react__login-alert'>
			<div className='inner' data-fadein={animationSettings.fadeIn} data-type={type} data-update={animationSettings.update}>
				{children}
			</div>
		</div>
	);
};

AlertLogin.propTypes = {
	type: PropTypes.oneOf(['error', 'success']),
	timestamp: PropTypes.number,
	children: PropTypes.node.isRequired
};

AlertLogin.defaultProps = {
	type: 'error',
	timestamp: Date.now()
};

export default AlertLogin;
