// extracted by mini-css-extract-plugin
var _1 = "#ffd8da";
var _2 = "#243048";
var _3 = "#f9e8ff";
var _4 = "#f5f7fb";
var _5 = "#e8ebf2";
var _6 = "#ffe6e6";
var _7 = "#e8ebf2";
var _8 = "#def1ff";
var _9 = "#dbe7ff";
var _a = "#dbf8fe";
var _b = "#e8ebf2";
var _c = "#dcdaff";
var _d = "#f9ffe6";
var _e = "#fff4dc";
export { _1 as "airbnb", _2 as "bright", _3 as "critical", _4 as "dark", _5 as "darkSmoke", _6 as "error", _7 as "extraDarkSmoke", _8 as "info", _9 as "primary", _a as "secondary", _b as "silver", _c as "stripe", _d as "success", _e as "warning" }
