import './Alert.scss';

import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Constants from 'Shared/constants';

const Alert = ({ children, className, display, type }) => {
	return (
		<div className={cx(className, `react__alert ${type}`)} data-display={display}>
			{children}
		</div>
	);
};

Alert.propTypes = {
	type: PropTypes.oneOf(Constants.TYPES),
	children: PropTypes.any,
	className: PropTypes.string,
	display: PropTypes.oneOf(['block', 'inline-block', 'flex'])
};

Alert.defaultProps = {
	type: 'primary',
	className: '',
	display: 'block',
	children: null
};

export default Alert;
