import './ContentLeft.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Button from 'ViatoUi/FormElements/Buttons/ButtonLogin';
import { Headline, Paragraph } from 'ViatoUi/Typo';

const ContentLeft = ({ callback, enabled }) => {
	return (
		<div className='area-no-bg-left'>
			<div className='content'>
				{enabled && (
					<>
						<Headline type='h1'>Noch keinen Account?</Headline>
						<Headline className='subline' type='h6'>
							Dann Kunde bei der Viato Suite werden, wir freuen uns darauf.
						</Headline>
						<Paragraph />
						<Paragraph />
						<Button type='secondary' width='225px' onClick={() => callback()}>
							Account erstellen
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

ContentLeft.propTypes = {
	enabled: PropTypes.bool,
	callback: PropTypes.func
};

ContentLeft.defaultProps = {
	enabled: false,
	callback: () => {}
};

export default ContentLeft;
