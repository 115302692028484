import './Button.scss';

import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Constants from 'Shared/constants';
import Tooltip from 'ViatoUi/DataDisplay/Tooltip';
import { FlexBox } from 'ViatoUi/Layouts/Flex';

const Button = ({
	buttonRef,
	className,
	disabled,
	focused,
	ghost,
	icon,
	iconBefore,
	id,
	isSmall,
	label,
	minified,
	onClick,
	rounded,
	style,
	tooltip,
	type
}) => {
	const componentId = id || `react__button-${String(Math.random()).replace('.', '')}-${Date.now()}`;
	const onClickProps = {};
	if (!disabled) {
		onClickProps.onClick = () => onClick();
		onClickProps.role = 'button';
		onClickProps.tabIndex = -1;
	}

	const ref = useRef(null);

	return (
		<>
			<div
				ref={buttonRef || ref}
				className={cx('react__button', className)}
				data-button-type={type}
				data-disabled={disabled}
				data-focused={focused}
				data-ghost={ghost}
				data-minified={minified}
				data-rounded={rounded}
				data-small={isSmall}
				id={componentId}
				style={style}
				{...onClickProps}
			>
				{iconBefore && (
					<FlexBox alignItems='center' className='icon-before' justifyContent='center'>
						<i className={iconBefore} />
					</FlexBox>
				)}

				{!minified && <span>{label}</span>}

				{icon && <i className={`icon-after ${icon}`} />}
			</div>
			{tooltip && <Tooltip target={buttonRef || ref} value={tooltip} followCursor />}
		</>
	);
};

Button.propTypes = {
	type: PropTypes.oneOf(Constants.TYPES).isRequired,
	ghost: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
	icon: PropTypes.string,
	iconBefore: PropTypes.string,
	minified: PropTypes.bool,
	isSmall: PropTypes.bool,
	className: PropTypes.string,
	tooltip: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	rounded: PropTypes.bool,
	focused: PropTypes.bool,
	buttonRef: PropTypes.object
};

Button.defaultProps = {
	id: null,
	icon: null,
	iconBefore: null,
	minified: false,
	isSmall: false,
	className: null,
	tooltip: '',
	disabled: false,
	style: {},
	rounded: false,
	focused: false,
	ghost: false,
	buttonRef: null
};

export default Button;
